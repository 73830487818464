@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    background-color: #F5F6F8 !important;
    font-family: 'Poppins', sans-serif !important;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

::selection {
    background: #BC09C7 !important;
    color: #ffffff !important;
    font-weight: bold;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #BC09C7 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #BC09C7;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

